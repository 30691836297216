import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap"
export default function Email(props) {
    const homePage = "homepage";
    const programPage = "program";
    const {
        source = programPage, // page to display on
        callingPage = "gastokalk", //item nbr
        emailAddr = "gastrokalk@gmail.com",
        emailMsg = "Zur Bestellung"
    } = props;
    const emailSubject = `${callingPage}?pg=${source}`;

    return (
        <React.Fragment>
            { source === homePage && 
                (
                    <div style={{ backgroundColor: "white", marginTop: "2rem"}} className="d-flex justify-content-center">
                        <Button id="bust" variant="outline-info" className="position-relative email-button">
                            <strong>{emailMsg}: </strong><a href={`mailto:${emailAddr}?subject=${emailSubject}`}>{emailAddr}</a>
                            {/* <Badge id="budge" className="position-absolute top-0 start-100 translate-middle rounded-pill email-badge">
                                <EnvelopeAt className="bi bi-envelope-at" />
                            </Badge> */}
                            <span className="visually-hidden">{emailMsg}</span>
                        </Button>
                    </div>
                )
            }
            { source === programPage && 
                (
                    <div className="pb-3">
                        <div className="is-info"><strong>{emailMsg}: </strong><a href={`mailto:${emailAddr}?subject=${emailSubject}`}>{emailAddr}</a></div>
                    </div>
                )
            }     
        </React.Fragment>
    );
}

Email.propTypes = {
    source: PropTypes.string,
    callingPage: PropTypes.string,
    emailAddr: PropTypes.string,
    emailMsg: PropTypes.string,
};
