import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Seo from "../components/seo"
import { Row, Col } from "react-bootstrap"
// import { CheckCircle, EnvelopeAt, People, PersonHeart, Quote } from "react-bootstrap-icons";
import Email from "../components/Email";

// eslint-disable-next-line
export const IndexPageTemplate = ({
    title,
    content,
    contentComponent,
    linkable1,
    linkable2,
    main,
}) => {
    const PageContent = contentComponent || Content;
    // const FtruckImg = getImage(main.image1)
    // console.info("home page location pathname: ", location)
    return (
        <>
            <Row>
                <Col className="col-lg-8 pb-2">
                    <h1 className="page-title title-border">
                        {title}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col lg={{ span: 8, offset: 2 }} className="">
                    <div className="item-summary">

                        <h2 className="lead-in hero-text">{linkable2}</h2>

                        <Row className="gx-0">
                            <Col>
                                <div className="hp-flyer-container">
                                    <Link to="anwendung/foodtruck/">
                                        <div className="hp-flyer-img-container">
                                            <img className="hp-flyer-img" src="/img/Sandwich2-1-cr.jpg" layout="constrain" alt="Foodtruck" />
                                        </div>
                                        <div className="hp-flyer-text">
                                            <div>Foodtruck</div>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                            <Col className="mx-4">
                                <div className="hp-flyer-container">
                                    <Link to="anwendung/kantine/">
                                        <div className="hp-flyer-img-container">
                                            <img className="hp-flyer-img" src="/img/a-flyergastro12.jpg" layout="fullWidth" alt="Kantine" />
                                        </div>
                                        <div className="hp-flyer-text">
                                            <div>Kantine</div>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="hp-flyer-container">
                                    <Link to="anwendung/imbiss/">
                                        <div className="hp-flyer-img-container">
                                            <img className="hp-flyer-img" src="/img/a-flyergastro10.jpg" layout="fullWidth" alt="Imbiss" />
                                        </div>
                                        <div className="hp-flyer-text">
                                            <div>Imbiss</div>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>

                        <Row className="gx-0 mb-4">
                            <Col>
                                <div className="hp-flyer-container">
                                    <Link to="anwendung/event/">
                                        <div className="hp-flyer-img-container">
                                            <img className="hp-flyer-img" src="/img/a-flyergastro3.jpg" layout="constrain" alt="Event" />
                                        </div>
                                        <div className="hp-flyer-text">
                                            <div>Event</div>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                            <Col className="mx-4">
                                <div className="hp-flyer-container">
                                    <Link to="anwendung/catering/?v=hp">
                                        <div className="hp-flyer-img-container">
                                            <img className="hp-flyer-img" src="/img/a-flyergastro14.jpg" layout="fullWidth" alt="Catering" />
                                        </div>
                                        <div className="hp-flyer-text">
                                            <div>Catering</div>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="hp-flyer-container">
                                <Link to="anwendung/restaurant/">
                                        <div className="hp-flyer-img-container">
                                            <img className="hp-flyer-img" src="/img/a-flyergastro15-cr.jpg" layout="fullWidth" alt="Restaurant" />
                                        </div>
                                        <div className="hp-flyer-text">
                                            <div>Restaurant</div>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>



                        <PageContent className="item-summary" content={content} />


                        {/* <p>{main.absatz1}</p>
                        <p>{main.absatz2}</p>
                        <p>{main.absatz3}</p>
                        <aside className="subtitle is-6 has-text-weight-semibold summary-aside">
                            <Quote className="bi bi-quote" />
                            <Link to="/products/gk01">Speisen und Getränke Kalkulator</Link> wird benutzt für kalkulieren, Inventur. Von der Programmen begeistert.
                            <div className="aside-footer"><cite>Lotto Kantine</cite></div>
                        </aside> */}
                        {/* <aside>
                            <blockquote className="subtitle is-6 has-text-weight-semibold pull-blockquote">
                                Wer benutzt gastrokalk?
                                <br /><People className="bi bi-people" /><Link to="/referenzen">Siehe Referenzen</Link>
                            </blockquote>
                        </aside> */}
                        {/* <p>{main.absatz4}</p> */}
                        {/* <aside className="is-6 has-text-weight-semibold summary-aside"><Quote className="bi bi-quote" />{main.pullquote1}</aside> */}
                        {/* <p>{main.absatz5}</p>
                        <aside className="pullquote-inline">
                            <blockquote>
                                <Quote className="bi bi-quote" />Keine Cloud, kein Server, keine Registrierung. Privat und immer zugänglich. Sparen Sie Zeit und Geld.
                            </blockquote>
                        </aside>
                        <p>{main.absatz6}</p>
                        <p>{main.absatz7}</p> */}
                        {/* <aside className="subtitle is-6 has-text-weight-semibold  summary-aside">
                            <blockquote>
                                <Quote className="bi bi-quote" />
                                {main.pullquote2}
                            </blockquote>
                        </aside> */}
                        {/* <p>{main.absatz8}</p> */}
                        {/* <aside className="pullquote-inline">
                            <blockquote>
                            <People className="bi bi-people" />
                                Wer benutzt gastrokalk?&nbsp;<Link to="/referenzen">Siehe Referenzen</Link>&nbsp;<PersonHeart className="bi bi-person-heart" />
                            </blockquote>
                        </aside> */}
                    </div>


                    {/* <Row className="gx-0" style={{ border: "1px solid brown" }}>
                        <Col style={{ border: "1px solid orange" }}>
                            <div style={{display: "grid", alignItems: "center", margin: "1em 0 2em 0"}}>
                                <Figure style={{border: "11px solid taupe"}}>
                                    <Figure.Image
                                        width={171}
                                        height={180}
                                        alt="171x180"
                                        src="/img/Sandwich2-1-cr.jpg"
                                    />
                                    <Figure.Caption className="text-center" style={{display: "grid", gridArea: "1/1", border: "1px solid orange"}}>Foodtruck</Figure.Caption>
                                </Figure>
                            </div>
                        </Col>
                        <Col className="mx-4" style={{ border: "1px solid orange" }}>
                            <Figure>
                                <Figure.Image
                                    width={171}
                                    height={180}
                                    alt="171x180"
                                    src="/img/a-flyergastro12.jpg"
                                    style={{display: "grid", gridArea: "1/1", border: "1px solid orange"}}
                                />
                                <Figure.Caption className="text-center" style={{gridArea: "1/1", position: "relative", placeItems: "center", display: "grid"}}>Kantine</Figure.Caption>
                            </Figure>
                        </Col>
                        <Col style={{ border: "1px solid orange" }}>
                            <Figure>
                                <Figure.Image
                                    width={171}
                                    height={180}
                                    alt="171x180"
                                    src="/img/a-flyergastro10.jpg"
                                />
                                <Figure.Caption className="text-center">Imbiss</Figure.Caption>
                            </Figure>
                        </Col>
                    </Row> */}

                   
                    <Email source="homepage" emailMsg="Mehr infos" />

                    <div className="d-flex justify-content-center pt-4">
                        <h3 className="page-title">developed by gastronomes for gastronomes</h3>
                    </div>
                </Col>
            </Row>
        </>
    );
};

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    content: PropTypes.string,
    linkable1: PropTypes.string,
    linkable2: PropTypes.string,
    main: PropTypes.shape({
        absatz1: PropTypes.string,
        absatz2: PropTypes.string,
        absatz3: PropTypes.string,
        absatz4: PropTypes.string,
        absatz5: PropTypes.string,
        absatz6: PropTypes.string,
        absatz7: PropTypes.string,
        absatz8: PropTypes.string,
        pullquote1: PropTypes.string,
        pullquote2: PropTypes.string,
        pullquote3: PropTypes.string,
        summary: PropTypes.string,
        summary1: PropTypes.string,
        description: PropTypes.string,
        image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        wasHeader: PropTypes.string,
        wasBullets: PropTypes.array,
        kannHeader: PropTypes.string,
        kannBullets: PropTypes.array,
        warumHeader: PropTypes.string,
        warumBullets: PropTypes.array,
        video: PropTypes.string,
    }),
};

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const location = "home"

    return (
        <Layout location={location}>
            <IndexPageTemplate
                intro={frontmatter.intro}
                image={frontmatter.image}
                title={frontmatter.title}
                linkable1={frontmatter.linkable1}
                linkable2={frontmatter.linkable2}
                main={frontmatter.main}
                contentComponent={HTMLContent}
                content={data.markdownRemark.html}
            />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            content: PropTypes.string,
            frontmatter: PropTypes.object,
        }),
    }),
};

export default IndexPage;

export const Head = () => <Seo title="Home" />

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
        heading
        linkable1
        linkable2
        main {
          absatz1
          absatz2
          absatz3
          absatz4
          absatz5
          absatz6
          absatz7
          absatz8
          pullquote1
          pullquote2
          pullquote3
          summary
          summary1
          image1 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 175, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 175, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 175, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          image4 {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 350, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
              }
            }
          }
          wasHeading
          wasBullets
          kannHeading
          kannBullets
          warumHeading
          warumBullets
          video
        }

      }
    }
  }
`;
